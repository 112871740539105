import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"

import ContentContainer from "../ContentContainer/contentcontainer"
import LotHeader from "../LotHeader/lotheader"
import LotIntro from "../LotIntro/lotintro"
import LotDisclaimer from "../LotDisclaimer/lotdisclaimer"
import LotAreas from "../LotAreas/lotareas"
import LotVideo from "../LotVideo/lotvideo"
import LotGallery from "../LotGallery/lotgallery"
import LotPlans from "../LotPlans/lotplans"
import LotElevationMap from "../LotElevationMap/lotelevationmap"
import LotRenders from "../LotRenders/lotrenders"
import LotData from "../../Data/lot-3.json"

const Container = styled.div`

`

function LotThree(){
    const data = useStaticQuery(graphql`
    query {
      one_bg: file(relativePath: { eq: "6cc4.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      main: file(relativePath: { eq: "5-casa-de-campo.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_one: file(relativePath: { eq: "6cc1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_two: file(relativePath: { eq: "6cc2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_three: file(relativePath: { eq: "6cc3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_four: file(relativePath: { eq: "6cc4.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_five: file(relativePath: { eq: "6cc5.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_six: file(relativePath: { eq: "6cc6.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f_one: file(relativePath: { eq: "f_5_1.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_one: file(relativePath: { eq: "r_5_1.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      r_three: file(relativePath: { eq: "r_5_3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_four: file(relativePath: { eq: "r_5_4.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_five: file(relativePath: { eq: "r_5_5.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_six: file(relativePath: { eq: "r_5_6.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_seven: file(relativePath: { eq: "r_5_7.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_eight: file(relativePath: { eq: "r_5_8.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_nine: file(relativePath: { eq: "r_5_9.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container >
            <LotHeader name="5 Casa De Campo" bg={data.one_bg.childImageSharp.fluid} />
            <LotDisclaimer />
            <LotIntro img={data.main.childImageSharp.fluid} desc={LotData.long_desc} />
            <LotGallery data={data} />
            <LotRenders data={data}/>
            <LotVideo videoId="460417330" />
            <LotPlans img={data} />
            <LotAreas src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25163.04242482045!2d-122.14899246178811!3d37.968253208777455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808564f8e5cd6d55%3A0x3bf81e90fd972e06!2s5%20Casa%20De%20Campo%2C%20Martinez%2C%20CA%2094553!5e0!3m2!1sen!2sus!4v1586591532462!5m2!1sen!2sus" />
        </Container>
    )
}

export default LotThree
